import React, { SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { history } from 'App';
import classNames from 'classnames';
import queryString from 'query-string';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Banner } from '@just-ai/just-ui';
import { Button, FormFeedback, Icon, Last4NumberDigitsInput, PhoneNumber } from '@just-ai/just-ui/dist';

import localize, { t } from 'localization';
import { getCountryList, getDomainData, isAxiosError } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import LoginService from 'service/LoginService';
import PhoneVerificationService from 'service/PhoneVerificationService';
import { ssoService } from 'service/SsoService';
import { BasePage, BaseStateTypes } from 'views/BasePage';
import { Error } from 'views/BasePage';
import { github, google } from 'views/BasePage/icons';
import ResendTimer from 'views/VerifyEmail/ResendTimer';

import { phoneverificationandloginLocalization } from './localization/phoneverificationandlogin.loc';

localize.addTranslations(phoneverificationandloginLocalization);

interface PhoneVerificationState extends BaseStateTypes {
  phoneNumber: { code: string; number: string; country?: string };
  step: number;
  verificationCode: string;
  isResendCodeEnabled: boolean;
  smsCode: string;
  smsResendExceeded: boolean;
  dontReceiveSms: boolean;
  isSendSmsCodeButtonDisabled: boolean;
}

class PhoneVerificationAndLogin extends BasePage<any, PhoneVerificationState> {
  static contextType = AppContext;

  state = {
    fetching: false,
    errors: [],
    phoneNumber: {
      code: this.context.appConfig.euroInstance ? '1' : '7',
      number: '',
    },
    verificationCode: '',
    step: 1,
    isResendCodeEnabled: false,
    smsCode: '',
    smsResendExceeded: false,
    dontReceiveSms: false,
    isLoginPage: this.props.location.pathname.includes('/c/login-with-phone'),
    isSendSmsCodeButtonDisabled: false,
    gRecaptchaResponse: null,
    loaded: true,
  };

  PhoneVerificationService = new PhoneVerificationService();
  accountsadminService = new AccountsadminService();
  LoginService = new LoginService();

  async componentDidMount() {
    const { isLoginPage } = this.state;
    const { language, appConfig, setCurrentUser } = this.context;
    const {
      location: { search },
    } = this.props;
    if (!appConfig.authorization.smsCodeAuthorizationEnabled) {
      history.push('/c/login');
    }

    this.supportAddOnMessageListener();

    if (!isLoginPage) {
      this.setState({
        fetching: true,
      });
      try {
        const { data } = await this.LoginService.checkIsUserAuthorized();
        setCurrentUser(data);

        const parsedLocationSearch = queryString.parse(search);
        const countryIsoCode = data.userData.countryIsoCode || (parsedLocationSearch.countryIsoCode as string);

        const countryList = getCountryList(language, appConfig.euroInstance);
        const userCountry = countryList.find(country => country[0] === countryIsoCode);
        if (userCountry) {
          this.setState({
            phoneNumber: { code: userCountry[3], number: '', country: countryIsoCode?.toLowerCase() },
          });
        }

        this.setState({
          fetching: false,
        });
      } catch (e) {
        history.push(`/c/login`);
      }
    }
  }

  handlePhoneNumberChange = (phoneNumber: PhoneVerificationState['phoneNumber']) => {
    if (phoneNumber.code) {
      this.setState({ phoneNumber });
    }
  };

  handleCodeChange = (smsCode: string) => this.setState({ smsCode });

  submit = (e: SyntheticEvent) => {
    e.preventDefault();
    const { step } = this.state;
    step === 1 ? this.submitFirstStep() : this.submitSecondStep();
  };

  submitFirstStep = async () => {
    const { location } = this.props;
    const { phoneNumber, isLoginPage } = this.state;
    const { appConfig } = this.context;
    let phone = phoneNumber.code + phoneNumber.number;
    phone = phone.replace(/\(|\)|\s|-/g, '');
    this.setState({
      fetching: true,
    });
    try {
      if (appConfig?.captcha?.enabled) {
        await this.executeCaptcha();
      }
      if (isLoginPage) {
        await this.PhoneVerificationService.sendSmsCodeForAuthorization({ phone }, this.state.gRecaptchaResponse);
      } else {
        await this.PhoneVerificationService.sendSmsCodeForPhoneVerification({ phone }, this.state.gRecaptchaResponse);
      }
      this.setState({
        errors: [],
        step: 2,
      });
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error.response?.data;
        this.resetCaptcha();
        if (errorData.error === 'accountsadmin.smscode.too.many.attempts') {
          this.setState({ smsResendExceeded: true });
        } else if (errorData.error === 'common.access.denied') {
          history.push(`/c/login${location?.search}`);
        } else {
          this.setState({
            fetching: false,
            errors: errorData.errors || [errorData],
          });
        }
      }
    } finally {
      this.setState({
        fetching: false,
      });
    }
  };

  submitSecondStep = async () => {
    const { smsCode, phoneNumber, isLoginPage } = this.state;

    const {
      location: { search },
    } = this.props;
    const { appConfig } = this.context;

    let phone = phoneNumber.code + phoneNumber.number;
    phone = phone.replace(/\(|\)|\s|-/g, '');

    this.setState({ fetching: true });

    try {
      if (isLoginPage) {
        await this.PhoneVerificationService.verifySmsCode({ code: smsCode, phone: phone });
      } else {
        await this.accountsadminService.verifySmsProfile(smsCode, phone);
      }

      const { redirectUrl } = getDomainData(search, appConfig?.domains);

      setTimeout(() => {
        ssoService.stayOrRedirect(redirectUrl as string);
      }, 3000);
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error.response?.data;
        if (errorData.error === 'accountsadmin.smscode.too.many.attempts') {
          this.setState({ smsResendExceeded: true });
        } else {
          if (
            errorData.error === 'accountsadmin.smscode.send.again' ||
            errorData.error === 'accountsadmin.smscode.enter.again'
          ) {
            this.setState({
              isResendCodeEnabled: true,
            });
          }
          if (errorData.error === 'accountsadmin.smscode.send.again' || errorData.args?.remainingAttempts === 0) {
            this.setState({ isSendSmsCodeButtonDisabled: true });
          }

          this.setState({
            fetching: false,
            errors: errorData.errors || [errorData],
          });
        }
      }
    }
  };

  resendSms = async () => {
    await this.submitFirstStep();
    this.setState({
      isResendCodeEnabled: false,
      isSendSmsCodeButtonDisabled: false,
    });
  };

  onTimerFinish = () => this.setState({ isResendCodeEnabled: true });

  toStepOne = () => {
    this.resetCaptcha();
    this.setState({
      step: 1,
      errors: [],
      smsResendExceeded: false,
      dontReceiveSms: false,
      isSendSmsCodeButtonDisabled: false,
      isResendCodeEnabled: false,
    });
  };

  toStepTwo = () => {
    this.setState({
      step: 2,
      errors: [],
      smsResendExceeded: false,
      dontReceiveSms: false,
      isSendSmsCodeButtonDisabled: false,
    });
  };

  toDontReceiveSmsInfo = () => this.setState({ dontReceiveSms: true });

  toLoginWithEmail = (e?: SyntheticEvent) => {
    e?.preventDefault();
    const { location } = this.props;
    history.push(`/c/login${location?.search}`);
  };

  renderHead = () => {
    const { step, phoneNumber, smsResendExceeded, dontReceiveSms, isLoginPage } = this.state;
    const { appConfig } = this.context;
    let text = 'PhoneVerificationAndLogin: email verified';
    if (isLoginPage) text = 'Login: form header text';
    if (step === 2) text = 'PhoneVerificationAndLogin: sms code title';
    if (smsResendExceeded) text = 'PhoneVerificationAndLogin: resend limits exceeded';
    if (dontReceiveSms) text = 'PhoneVerificationAndLogin: dont receive sms';
    return (
      <div
        className={classNames('base-page_formarea-head phone-verification-head', {
          'resend-sms-exceeded': smsResendExceeded || dontReceiveSms,
        })}
      >
        <h1>{t(text, appConfig?.euroInstance ? 'support@ds.tovie.ai' : 'support@just-ai.com')}</h1>
        {(step === 2 || smsResendExceeded || dontReceiveSms) && (
          <div className='phone-verification-head-phone'>
            <span>+{phoneNumber.code + phoneNumber.number}</span>
            <span onClick={this.toStepOne}>{t('PhoneVerificationAndLogin: change number')}</span>
          </div>
        )}
      </div>
    );
  };

  renderInputs = () => {
    const { phoneNumber, step, isResendCodeEnabled, errors, smsResendExceeded, dontReceiveSms, isLoginPage } =
      this.state;
    const { language, appConfig } = this.context;

    const countryList = getCountryList(language, appConfig.euroInstance);

    if (smsResendExceeded || dontReceiveSms) return null;

    return step === 1 ? (
      <>
        <div
          className={classNames('form-row', {
            'with-error': !isLoginPage || (isLoginPage && Boolean(this.renderFieldError())),
          })}
        >
          <label>{t('PhoneVerificationAndLogin: phone input label')}</label>
          <PhoneNumber
            countryList={countryList}
            error={false}
            focused
            onChange={this.handlePhoneNumberChange}
            phoneNumber={phoneNumber}
            placeholderSearch={t('PhoneVerificationAndLogin: search placeholder')}
            selectFixedPosition={false}
          />
          <FormFeedback tag='div' valid={false}>
            {errors?.length > 0
              ? this.renderFieldError()
              : !isLoginPage && <small className='text-muted'>{t('PhoneVerificationAndLogin: sms helper text')}</small>}
          </FormFeedback>
        </div>
      </>
    ) : (
      <>
        <div className='form-row send-code-row'>
          <Last4NumberDigitsInput onChange={this.handleCodeChange} isError={false} isPlaceholderHidden />
          <FormFeedback tag='div' valid={false}>
            <>
              {!isResendCodeEnabled && (
                <small className='text-muted'>
                  <ResendTimer
                    finishTimer={this.onTimerFinish}
                    timer={60}
                    attemptsCount={4}
                    timerText='PhoneVerificationAndLogin: code helper text'
                    compact
                    hidden={Boolean(this.renderFieldError())}
                  />
                </small>
              )}
              {this.renderFieldError()}
            </>
          </FormFeedback>
        </div>
        {isResendCodeEnabled && (
          <div className='form-row send-code-row resend-code-buttons'>
            <span onClick={this.resendSms}>{t('PhoneVerificationAndLogin: resend sms')}</span>
            <span onClick={this.toDontReceiveSmsInfo}>{t('PhoneVerificationAndLogin: dont have sms')}</span>
          </div>
        )}
      </>
    );
  };

  renderButtons = () => {
    const { step, smsCode, phoneNumber, smsResendExceeded, dontReceiveSms, isLoginPage, isSendSmsCodeButtonDisabled } =
      this.state;
    const {
      location: { search },
    } = this.props;
    const { appConfig, currentUser } = this.context;

    const phone = phoneNumber.number.replace(/\(|\)|\s|-/g, '');
    const { redirectUrl } = getDomainData(search, appConfig?.domains);

    return (
      <>
        <div
          className={classNames('base-page_formarea-buttons phone-verification-buttons', {
            'step-2': step === 2,
            'resend-sms-exceeded': smsResendExceeded || dontReceiveSms,
            'login-page': isLoginPage && step === 1 && !smsResendExceeded && !dontReceiveSms,
          })}
        >
          {!dontReceiveSms && !isLoginPage && (
            <Button
              outline
              color='primary'
              onClick={() => {
                if (!currentUser.userData.accountId) {
                  history.push(`/c/select-project-group?redirectUrl=${redirectUrl}`);
                } else {
                  ssoService.stayOrRedirect(redirectUrl as string);
                }
              }}
            >
              {t('PhoneVerificationAndLogin: button skip')}
            </Button>
          )}
          {!smsResendExceeded && !dontReceiveSms && (
            <Button
              color='primary'
              disabled={(step === 1 && !phone) || (step === 2 && (smsCode.length < 4 || isSendSmsCodeButtonDisabled))}
            >
              {t('PhoneVerificationAndLogin: button to next step')}
            </Button>
          )}
          {appConfig.authorization.oauth2AuthorizationEnabled &&
            !smsResendExceeded &&
            !dontReceiveSms &&
            isLoginPage &&
            step === 1 && (
              <div className='to-other-login'>
                <p>{t('PhoneVerificationAndLogin: login with')}</p>
                <div className='to-other-login-buttons'>
                  <Button color='secondary' outline onClick={this.toLoginWithEmail}>
                    <Icon name='farEnvelope' /> {t('PhoneVerificationAndLogin: login with email')}
                  </Button>
                  <Button color='secondary' outline onClick={() => this.loginWithProvider('google')}>
                    {google} {t('Login: login with google')}
                  </Button>
                  <Button color='secondary' outline onClick={() => this.loginWithProvider('github')}>
                    {github} {t('Login: login with github')}
                  </Button>
                </div>
              </div>
            )}
          {((isLoginPage && smsResendExceeded) || dontReceiveSms) && (
            <Button
              outline
              color='secondary'
              onClick={isLoginPage && smsResendExceeded ? this.toLoginWithEmail : this.toStepTwo}
            >
              {isLoginPage && smsResendExceeded && <Icon name='farEnvelope' />}
              {t(
                isLoginPage && smsResendExceeded
                  ? 'PhoneVerificationAndLogin: button to email login'
                  : 'PhoneVerificationAndLogin: button back'
              )}
            </Button>
          )}
        </div>
      </>
    );
  };

  renderFieldError = () => {
    const { errors } = this.state;
    return errors.length > 0 ? (
      <>
        {(errors as Error[])
          .filter(error => error.error !== 'common.error.internal')
          .map(error => {
            let newError = { ...error };
            const remainingAttempts = newError.args?.remainingAttempts;
            if (remainingAttempts === 0) {
              newError.error = 'accountsadmin.smscode.send.again';
            }
            const remainingAttemptsString = localize.decliner(
              [
                'PhoneVerificationAndLogin: attempts 1',
                'PhoneVerificationAndLogin: attempts 2',
                'PhoneVerificationAndLogin: attempts 5',
              ],
              remainingAttempts
            );
            return t(
              `PhoneVerificationAndLogin:BE-error ${newError['error']}`,
              error.uuid ?? t(remainingAttemptsString, remainingAttempts)
            );
          })}
      </>
    ) : null;
  };

  renderFooter = () => {
    const { appConfig } = this.context;
    const { location } = this.props;
    const { isLoginPage, step, smsResendExceeded, dontReceiveSms } = this.state;

    return isLoginPage && step === 1 && !smsResendExceeded && !dontReceiveSms ? (
      <div className='base-page_formarea-footer login'>
        {appConfig.registration.enabled && (
          <p>
            {t('Login: no account')}
            <NavLink to={`/c/register${location?.search}`}>{t('Login: register link')}</NavLink>
          </p>
        )}
      </div>
    ) : null;
  };

  renderCommonErrors = (): JSX.Element | void | false | unknown => {
    const { errors } = this.state;
    const commonErrors: Error[] = errors.filter((error: Error) => error.error === 'common.error.internal');
    return (
      commonErrors?.length > 0 && (
        <div className='base-page_formarea-errors'>
          <Banner
            type='danger'
            withIcon
            BannerText={() => (
              <>
                {commonErrors.map(error => (
                  <div key={`commonError_${error.error}`}>{t(`BasePage:BE-error ${error.error}`, error.uuid)}</div>
                ))}
              </>
            )}
          />
        </div>
      )
    );
  };
}

export default PhoneVerificationAndLogin;
