import { history } from 'App';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import countries from 'countries-list/dist/countries.min.json';
import moment from 'moment';
import * as queryString from 'query-string';
import { DomainOptions, LanguageOptions, Locale } from '@just-ai/api/dist/generated/Accountsadmin';

import aimybox from 'images/aimybox.svg';
import aimychat from 'images/aimychat.svg';
import aimylogic from 'images/aimylogic.svg';
import aimyvoice from 'images/aimyvoice.svg';
import caila from 'images/caila.svg';
import cloud from 'images/cloud.svg';
import jaicf from 'images/jaicf.svg';
import jaicp from 'images/jaicp.svg';
import { Error } from 'views/BasePage';

import { FbEvent, GoogleEvent, VkEvent, YandexEvent } from './types';

const russianCountriesName = require('i18n-iso-countries/langs/ru.json');

export const getUserLanguage = (language?: LanguageOptions) => {
  const languageFromLocalStorage = localStorage.getItem('USER_LANGUAGE');
  if (!language) return languageFromLocalStorage ?? 'eng';
  if (
    languageFromLocalStorage &&
    language.allowed.includes(langToUpperCase(languageFromLocalStorage as string) as Locale)
  ) {
    return languageFromLocalStorage!;
  } else {
    let lang = window.navigator.language.split('-')[0];

    if (!language.allowed.includes(langToUpperCase(lang as string) as Locale)) {
      // @ts-ignore
      lang = language?.default;
    }
    return getLangByCode(lang);
  }
};

export const getLangByCode = (code: string) => {
  switch (code?.toLowerCase()) {
    case 'ru': {
      return 'ru';
    }
    case 'cn':
    case 'zh': {
      return 'cn';
    }
    case 'br':
    case 'pt':
      return 'pt';
    default:
      return 'eng';
  }
};

const timezone = new Date().getTimezoneOffset();
let headers = {
  language: getUserLanguage().substr(0, 2).toUpperCase(),
  Timezone: timezone,
};
let axiosWithSessionCheck = axios.create({
  headers,
  validateStatus: status => {
    if (status === 401) {
      if (['/c/login', '/c/register', '/c/setPassword', '/c/remind'].includes(window.location.pathname) === false) {
        history.push({
          pathname: '/c/login',
        });
      }
      return false;
    } else if (status === 403) {
      return false;
    } else if (status === 503) {
      history.push({
        pathname: '/503',
      });
    }
    return status >= 200 && status < 300;
  },
});

axiosWithSessionCheck.interceptors.request.use(request => {
  let modifiedRequest = { ...request };
  modifiedRequest.url = modifiedRequest?.url?.replace(/\.\.\//g, '');
  return modifiedRequest;
});

axiosWithSessionCheck.interceptors.response.use(response => {
  const { data, config } = response;
  if (typeof data === 'string' && data.endsWith('</html>')) {
    console.log(
      '%cBACKEND / NGINX ERROR!',
      'color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;'
    );
    console.log(
      `%crequest ${config?.method?.toUpperCase()} to ${config.url} returns html`,
      'color: red; font-family: sans-serif; font-size: 2em; font-weight: normal; text-shadow: #000 1px 1px;'
    );
    return Promise.reject(
      `BACKEND/NGINX ERROR: request ${config?.method?.toUpperCase()} to ${config.url} returns html`
    );
  }
  return response;
});

// @ts-ignore
axiosWithSessionCheck._get = (url, options) => {
  return new Promise((resolve, reject) => {
    axiosWithSessionCheck
      .get(url, options)
      .then(response => {
        response.config = { ...response.config, ...options };
        resolve(response);
      })
      .catch(reject);
  });
};
// @ts-ignore
const postPutDeleteInterceptor =
  (method: 'post' | 'put' | 'patch' | 'delete') => (url: string, data: any, config: AxiosRequestConfig) => {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      axiosWithSessionCheck[method](url, data, config)
        .then((response: AxiosResponse) => {
          response.config = { ...response.config, ...config };
          resolve(response);
        })
        .catch(reject);
    });
  };
// @ts-ignore
axiosWithSessionCheck._post = postPutDeleteInterceptor('post');
// @ts-ignore
axiosWithSessionCheck._put = postPutDeleteInterceptor('put');
// @ts-ignore
axiosWithSessionCheck._delete = postPutDeleteInterceptor('delete');

export function updateLanguage(language: string) {
  const MOMENT_LOCALE_MAP = {
    ru: 'ru',
    eng: 'en',
    cn: 'en-gb',
  };

  // @ts-ignore
  moment.locale(MOMENT_LOCALE_MAP[language] || 'en');
  setAxiosHeadersLanguage(language.substr(0, 2).toUpperCase());
}

export function setAxiosHeadersLanguage(val: string) {
  axiosWithSessionCheck.defaults.headers.language = val;
}

axiosWithSessionCheck.interceptors.response.use(response => {
  const { data } = response;
  if (typeof data !== 'string') {
    const errors = data.errors || [data];
    const noSession = errors.find((error: Error) => error.error === 'common.access.denied');
    if (noSession) {
      history.push(`/c/login`);
    }
  }
  return response;
});

export { axiosWithSessionCheck as axios };

export function getCountryList(lang: string, isEuroInstance: boolean) {
  const mainList: [string, string, string, string][] = [];
  const sngList: { [countryKey: string]: [string, string, string, string] } = {};
  Object.keys(countries).forEach(countryKey => {
    const { name, phone } = countries[countryKey as keyof typeof countries];
    const localizeCountryName = lang === 'ru' ? russianCountriesName.countries[countryKey] : name;
    let phoneCode = phone.split(',')[0];
    if (countryKey === 'KZ') {
      phoneCode = '7';
    }
    const value: [string, string, string, string] = [
      countryKey,
      localizeCountryName,
      localizeCountryName.toLowerCase(),
      phoneCode,
    ];

    if (['KZ', 'RU', 'UA', 'BY'].includes(countryKey) && !isEuroInstance) {
      sngList[countryKey as keyof typeof countries] = value;
    } else {
      mainList.push(value);
    }
  });

  return isEuroInstance
    ? [...mainList.sort((a, b) => (a[1] > b[1] ? 1 : -1))]
    : [sngList['RU'], sngList['KZ'], sngList['UA'], sngList['BY'], ...mainList.sort((a, b) => (a[1] > b[1] ? 1 : -1))];
}

export const getLogoByProduct = (product: string) => {
  switch (product) {
    case 'aimylogic':
      return aimylogic;
    case 'aimyvoice':
      return aimyvoice;
    case 'cc':
      return cloud;
    case 'jaicp':
      return jaicp;
    case 'jaicf':
      return jaicf;
    case 'caila':
      return caila;
    case 'aimybox':
      return aimybox;
    case 'aimychat':
      return aimychat;
    default:
      return;
  }
};

export function getGoogleTag(isEuroInstance: boolean) {
  const testEnvTag = 'GTM-PFWDJ2F';
  let prodEnvTag = 'GTM-KHB42L5';
  if (isEuroInstance) {
    prodEnvTag = 'GTM-5KSFR96';
  }

  if (window.location.host.startsWith('localhost')) return '';
  return window.location.host.endsWith('lo.test-ai.net') ? testEnvTag : prodEnvTag;
}

export function getYandexId() {
  const testEnvTag = 86472753;
  const prodEnvTag = 86472753;

  if (window.location.host.startsWith('localhost')) return '';
  return window.location.host.endsWith('lo.test-ai.net') ? testEnvTag : prodEnvTag;
}

export const isDev = () => {
  // @ts-ignore
  return process.env.NODE_ENV === 'development' || window.isDev;
};

export const sendAnalyticEvents = (GA: GoogleEvent, YM: YandexEvent, VK: VkEvent, FB: FbEvent) => {
  if (isDev()) {
    console.log(GA, YM, VK, FB);
  } else {
    if (GA) {
      // @ts-ignore
      window.dataLayer.push({
        event: GA.event,
        eventCategory: GA.eventCategory,
        eventAction: GA.eventAction,
        eventLabel: GA.eventLabel,
        eventValue: GA.eventValue,
        ...GA.options,
      });
    }

    // @ts-ignore
    if (YM && window.ym) {
      // @ts-ignore
      window.ym(getYandexId(), YM.event, YM.eventType);
    }

    // @ts-ignore
    if (VK && window.VK) {
      // @ts-ignore
      window.VK.Retargeting.Event(VK.event);
    }

    // @ts-ignore
    if (FB && window.FB) {
      // @ts-ignore
      window.fbq(FB.event, FB.eventType);
    }
  }
};

export const langToUpperCase = (language: string) => {
  return language.toUpperCase().slice(0, 2);
};

export const getDomainData = (search: string, domains: { [product: string]: DomainOptions }) => {
  const parsedLocationSearch = queryString.parse(search.replace('?', ''));
  const host = window.location.host;
  let product = null;
  let productToRedirect = null;

  let redirectUrl = parsedLocationSearch?.redirectUrl;
  let theme = '';

  (Object.values(domains) as DomainOptions[]).forEach((domain: DomainOptions) => {
    if (domain.domain === host) {
      product = domain.product;
      productToRedirect = domain.product;
      theme = domain.defaultTheme;
    }
  });

  if (redirectUrl) {
    const redirectToDomainObject = (Object.values(domains) as DomainOptions[]).find(
      domain =>
        redirectUrl &&
        domain.domain &&
        (redirectUrl as string).startsWith(`${window.location.protocol}//${domain.domain}`)
    );
    if ((redirectToDomainObject && !redirectToDomainObject.redirectAllowed) || !redirectToDomainObject) {
      redirectUrl = window.location.href;
    } else {
      productToRedirect = redirectToDomainObject.product;
    }
  } else {
    redirectUrl = window.location.origin;
  }

  if (isDev()) {
    product = domains.cc.product;
    theme = domains.cc.defaultTheme;
    redirectUrl = `${window.location.protocol}//${domains.cc.domain}`;
  }

  return { redirectUrl, product, theme, productToRedirect };
};

export const getTosAndPpLinksFromOptions = (countryIsoCode: string, domains: { [product: string]: DomainOptions }) => {
  const { product } = getDomainData('', domains);
  const tosAndPos = domains[product as keyof typeof domains].tosAndPos;
  const tosAndPosLanguage = countryIsoCode?.toLowerCase();
  const tosAndPosByLanguage = tosAndPos[tosAndPosLanguage] || tosAndPos.eng;
  const termsOfUseUrl = tosAndPosByLanguage.termsOfUseUrl;
  const privacyPolicyUrl = tosAndPosByLanguage.privacyPolicyUrl;
  return { termsOfUseUrl, privacyPolicyUrl };
};

export const getCurrentDomainObject = (domains: { [product: string]: DomainOptions }) => {
  return Object.values(domains).find((domainData: DomainOptions) => domainData.domain === window.location.hostname);
};

export const isAxiosError = (e: any): e is AxiosError<any> => {
  return e?.response?.data;
};
