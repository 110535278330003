import { create } from 'zustand';
import { RoleDto, UserMode, UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountAndUserService from '@just-ai/api/dist/services/AccountsAndUsersService';
import { AppLogger } from '@just-ai/logger';

import LoginService from 'service/LoginService';
import UserService from 'service/UserService';

export type Paging = {
  pageNum: number;
  pageSize: number;
  totalCount: number;
};

export type UserListType = {
  paging?: Paging;
  records: UserSearchRecord[];
};

export interface UsersState {
  allowedModes: UserMode[];
  users: UserListType;
  admins: UserListType;
  usersOrAdminsLoading: boolean;
  roles: RoleDto[];
  loadUsers({ searchText, page, pageSize }: { searchText?: string; page?: number; pageSize?: number }): void;
  loadUsersWithExcludedAccount({
    searchText,
    page,
    pageSize,
    excludeAccountId,
  }: {
    searchText?: string;
    page?: number;
    pageSize?: number;
    excludeAccountId?: number;
  }): void;
  loadAdmins({ searchText, page, pageSize }: { searchText?: string; page?: number; pageSize?: number }): void;
  loadRoles: () => void;
  getAllowedModes: (userId: number) => void;
  changeModeAndRedirect: (userMode: UserMode, forDomain?: string, redirectUrl?: string) => Promise<string | null>;
}

const AccountAndUserServiceInstance = new AccountAndUserService();
const UserServiceInstance = new UserService();
const LoginServiceInstance = new LoginService();

export const useUsersStore = create<UsersState>(set => ({
  allowedModes: [],
  users: {
    records: [],
  },
  admins: {
    records: [],
  },
  roles: [],
  usersOrAdminsLoading: false,
  async loadUsers({ searchText, page, pageSize }: { searchText?: string; page?: number; pageSize?: number }) {
    set({ usersOrAdminsLoading: true });
    const response = await AccountAndUserServiceInstance.findUsersByFilterAsSadmin({
      page,
      searchText: searchText ?? '',
      pageSize,
    }).catch(AppLogger.createNetworkHandler('loadUsers'));
    if (!response) return set({ usersOrAdminsLoading: false });
    set({ users: response, usersOrAdminsLoading: false });
  },

  async loadRoles() {
    const response = await AccountAndUserServiceInstance.getInternalRoles().catch(
      AppLogger.createNetworkHandler('getInternalRoles')
    );
    if (!response) return;
    set({ roles: response });
  },

  async loadAdmins({ searchText, page, pageSize }: { searchText?: string; page?: number; pageSize?: number }) {
    set({ usersOrAdminsLoading: true });
    const response = await AccountAndUserServiceInstance.findInternalUsersByFilterAsSadmin({
      page,
      searchText: searchText ?? '',
      pageSize,
    }).catch(AppLogger.createNetworkHandler('loadUsers'));
    if (!response) return set({ usersOrAdminsLoading: false });
    set({ admins: response, usersOrAdminsLoading: false });
  },

  async getAllowedModes(userId: number) {
    const data = await UserServiceInstance.getAllowedModes(userId).catch(
      AppLogger.createNetworkHandler('getAllowedModes')
    );
    if (data) {
      set({ allowedModes: data.allowedModes });
    }
  },

  async changeModeAndRedirect(userMode: UserMode, forDomain?: string, redirectUrl?: string) {
    const response = await LoginServiceInstance.changeModeAndRedirect(userMode, forDomain, redirectUrl).catch(
      AppLogger.createNetworkHandler('changeModeAndRedirect')
    );
    if (!response) return null;
    return response;
  },

  async loadUsersWithExcludedAccount({
    searchText,
    page,
    pageSize,
    excludeAccountId,
  }: {
    searchText?: string;
    page?: number;
    pageSize?: number;
    excludeAccountId: number;
  }) {
    set({ usersOrAdminsLoading: true });
    const response = await AccountAndUserServiceInstance.findUsersByFilterAsSadmin({
      page,
      searchText: searchText ?? '',
      pageSize,
      excludeAccountId,
      excludeAccountByInvitations: true,
    }).catch(AppLogger.createNetworkHandler('loadUsersWithExcludedAccount'));
    if (!response) return set({ usersOrAdminsLoading: false });
    set({ users: response, usersOrAdminsLoading: false });
  },
}));
