import React from 'react';
import { history } from 'App';
import queryString from 'query-string';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { AppLogger } from '@just-ai/logger';

import { t } from 'localization';
import { sendAnalyticEvents } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import LoginService from 'service/LoginService';
import { ssoService } from 'service/SsoService';
import { utmKeeperService } from 'service/UtmKeeper';
import { BasePage, Error } from 'views/BasePage';

const authBlocked = 'accountsadmin.authorization.account.blocked';
const notFound = 'accountsadmin.user.by.email.not.found';
const authDisabled = 'accountsadmin.authorization.user.disabled';
const onlySso = 'accountsadmin.authorization.user.can_login_only_with_external_sso';
const ssoForbidden = 'accountsadmin.authorization.user.external_sso_access_forbidden';

class State {
  loaded: boolean = false;
  ssoForbiddenError: string = '';
  errors: Error[] | [] = [];
}
class OauthPage extends BasePage<any, any> {
  static contextType = AppContext;

  LoginService = new LoginService();
  AccountsadminService = new AccountsadminService();

  state = new State();

  componentDidMount() {
    const { appConfig } = this.context;

    const parsedLocationSearch = queryString.parse(this.props.location.search.replace('?', ''));
    const openIdUserNotFoundError = parsedLocationSearch?.errorCode;

    if (!appConfig.authorization.oauth2AuthorizationEnabled && !appConfig.authorization.externalSsoEnabled) {
      history.push('/c/login');
    }

    if (ssoForbidden === parsedLocationSearch.errorCode) {
      this.getSsoForbiddenData();
    }
    const load = async () => {
      if (openIdUserNotFoundError) {
        return this.setState({ loaded: true });
      }
      try {
        const { data: currentUser } = await this.LoginService.checkIsUserAuthorized();

        sendAnalyticEvents(
          {
            event: 'GAEvent',
            eventAction: `${parsedLocationSearch.event} success`,
            eventCategory: parsedLocationSearch.event as string,
            eventLabel: parsedLocationSearch.provider as string,
            options: { userId: currentUser.userData.userId },
          },
          {
            event: 'reachGoal',
            eventType: parsedLocationSearch.event as string,
          },
          { eventType: parsedLocationSearch.event as string },
          {
            event: 'track',
            eventType: parsedLocationSearch.event === 'registration' ? 'CompleteRegistration' : 'Login',
          }
        );

        const redirectUrl = localStorage.CLOUD_REDIRECT_URL;
        utmKeeperService.clearUtmMarks();

        setTimeout(() => {
          localStorage.removeItem('CLOUD_REDIRECT_URL');
          if (
            appConfig.registration.userNeedToSpecifyCountryIsoCode &&
            !currentUser.userData.countryIsoCode &&
            !currentUser.userData.internal &&
            currentUser.userData.accountId
          ) {
            history.push(`/c/select-country?redirectUrl=${redirectUrl}`);
          } else {
            ssoService.stayOrRedirect(redirectUrl);
          }
        }, 3000);
      } catch (e) {
        console.log(e);
      }
    };

    load();
  }

  getSsoForbiddenData = async () => {
    const errorDetail = await this.AccountsadminService.getExternalSsoUserForbiddenError().catch(
      AppLogger.createNetworkHandler('getAllowedAccounts')
    );

    if (!errorDetail) return;

    if (errorDetail.errorRedirectUrl) {
      window.location.href = errorDetail.errorRedirectUrl;
      return;
    }

    if (errorDetail.errorMessage) {
      this.setState({ ssoForbiddenError: errorDetail.errorMessage });
    }
  };

  renderHead = () => {
    const {
      location: { search },
    } = this.props;
    const parsedLocationSearch = queryString.parse(search.replace('?', ''));
    const openIdUserError = parsedLocationSearch?.errorCode;
    const openIdUserNotFoundEmail = parsedLocationSearch?.email as string;
    const isEmailExist =
      openIdUserNotFoundEmail && openIdUserNotFoundEmail !== 'null' && openIdUserNotFoundEmail !== 'undefined';

    const noEmailExist = openIdUserError === notFound && !isEmailExist;

    if (openIdUserError === ssoForbidden && !this.state.ssoForbiddenError) return null;

    if ([authBlocked, notFound, authDisabled, onlySso, ssoForbidden].includes(openIdUserError as string)) {
      return (
        <h3 className='oauth-error'>
          <span
            dangerouslySetInnerHTML={{
              __html:
                this.state.ssoForbiddenError ||
                t(
                  `OauthPage: oauth error text ${openIdUserError}${noEmailExist ? ' no email' : ''}`,
                  openIdUserNotFoundEmail
                ),
            }}
          />
        </h3>
      );
    }
  };
}

export default OauthPage;
