import React, { createRef, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { history } from 'App';
import classNames from 'classnames';
import queryString from 'query-string';
import { RegistrationWithInvitationRequestDto } from '@just-ai/api/dist/generated/Accountsadmin';
import RegisterService from '@just-ai/api/dist/services/AccountsadminService';
import { Button, Checkbox, Icon, InputText } from '@just-ai/just-ui';

import localization, { t } from 'localization';
import { getDomainData, getTosAndPpLinksFromOptions, isAxiosError } from 'pipes/functions';

import LoginService from 'service/LoginService';
import { ssoService } from 'service/SsoService';
import { BasePage, BasePropTypes, Error } from 'views/BasePage';
import { github, google } from 'views/BasePage/icons';
import { registerLocalization } from 'views/Register/localization/register.loc';

localization.addTranslations(registerLocalization);

const REGISTER_FIELDS = ['name', 'email', 'password'];

class State {
  showPassword: boolean = false;
  isTermsOfUseChecked: boolean = false;
  errors: Error[] | [] = [];
  fetching: boolean = false;
  gRecaptchaResponse: string | undefined = undefined;
  loaded: boolean = true;
}

export default class RegistrationWithInvite extends BasePage<BasePropTypes, State> {
  name = 'RegistrationWithInvite';
  state = new State();

  form = {
    name: createRef<HTMLInputElement>(),
    email: createRef<HTMLInputElement>(),
    password: createRef<HTMLInputElement>(),
  };
  recaptchaInstance: any;

  RegisterService = new RegisterService();
  LoginService = new LoginService();

  componentDidMount() {
    const { appConfig } = this.context;
    const { location } = this.props;
    const parsedLocationSearch = queryString.parse(location.search);

    if (!appConfig.registration.enabled) {
      history.push('/c/login');
    }
    this.supportAddOnMessageListener();

    if (parsedLocationSearch.invitationEmail && this.form.email.current) {
      this.form.email.current.value = parsedLocationSearch.invitationEmail as string;
    }

    const load = async () => {
      try {
        this.setState({ fetching: true });
        await this.LoginService.checkIsUserAuthorized();
        window.location.href = '/';
      } catch (e) {
        if (isAxiosError(e) && e.response?.data.error === 'accountsadmin.email.verification.not.verified') {
          history.push('/c/verify-email');
        }
      } finally {
        this.setState({ fetching: false });
      }
    };

    load();
    this.checkDomainForDirectSso();
  }

  componentWillUnmount() {
    this.captchaReadyInterval && clearInterval(this.captchaReadyInterval);
  }

  validate = (registerObject: RegistrationWithInvitationRequestDto) => {
    const { isTermsOfUseChecked, errors } = this.state;

    const commonErrors: Error[] = errors.filter((error: Error) => !error?.args?.path);
    const fieldErrors: { args: { path: string } }[] = [];

    REGISTER_FIELDS.forEach((field: string) => {
      if (!registerObject[field as keyof RegistrationWithInvitationRequestDto]) {
        fieldErrors.push({
          args: {
            path: field,
          },
        });
      }
    });

    if (!isTermsOfUseChecked) {
      fieldErrors.push({
        args: { path: 'termsOfUse' },
      });
    }

    this.setState({ errors: [...commonErrors, ...fieldErrors] });
    return fieldErrors.length === 0;
  };

  submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { language, appConfig } = this.context;
    const { location } = this.props;
    const { name, email, password } = this.form;

    const parsedLocationSearch = queryString.parse(location.search);

    const { product } = getDomainData(location.search, appConfig?.domains);

    const registerObject: RegistrationWithInvitationRequestDto = {
      name: name.current?.value?.trim() || '',
      email: email.current?.value?.trim() || '',
      password: password.current?.value?.trim() || '',
      language: language.substr(0, 2).toUpperCase(),
      accountId: parseInt(parsedLocationSearch.invitationAccountId as string),
    };

    if (!this.validate(registerObject)) return;

    if (product) registerObject.product = product;

    this.setState({
      fetching: true,
    });

    if (appConfig?.captcha?.enabled) {
      await this.executeCaptcha();
    }

    try {
      await this.RegisterService.registerWithInvitation(registerObject, this.state.gRecaptchaResponse);
      this.setState({
        fetching: false,
      });
      ssoService.stayOrRedirect(`${window.location.origin}/`);
    } catch (error) {
      this.resetCaptcha();
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];
        const redirectError = errors.find((error: Error) =>
          [
            'accountsadmin.account.invitation.not_found',
            'accountsadmin.account.invitation.expired',
            'accountsadmin.account.invitation.multiple_registered_users_with_same_email',
            'accountsadmin.account.invitation.registration_disabled',
          ].includes(error.error || '')
        );
        redirectError && history.push(`/c/error?errorCode=${redirectError}`);
        this.setState({
          errors: errors,
          fetching: false,
        });
      }
    }
  };

  togglePasswordShow = () => this.setState({ showPassword: !this.state.showPassword });

  toggleTermsOfUseLink = () => this.setState({ isTermsOfUseChecked: !this.state.isTermsOfUseChecked });

  renderInputs = () => {
    const { showPassword, isTermsOfUseChecked, errors } = this.state;
    const {
      appConfig: { domains },
      language,
    } = this.context;

    const termsOfUseError: Error | undefined = errors.find((error: Error) => error.args?.path === 'termsOfUse');

    const { termsOfUseUrl, privacyPolicyUrl } = getTosAndPpLinksFromOptions(language, domains);

    return (
      <>
        <div className={classNames('form-row', { 'with-error': Boolean(this.renderFieldError('email')) })}>
          <label htmlFor='email'>{t(`Register: field email label`)}</label>
          <InputText
            name='email'
            id='email'
            innerRef={this.form.email}
            placeholder='user@example.com'
            disabled
            data-test-id='RegisterWithInvite.Email'
          />
          {this.renderFieldError('email')}
        </div>
        <div className={classNames('form-row', { 'with-error': Boolean(this.renderFieldError('name')) })}>
          <label htmlFor='name'>{t(`Register: field name label`)}</label>
          <InputText
            name='name'
            id='name'
            innerRef={this.form.name}
            placeholder={t(`Register: field name label`)}
            maxLength={200}
            data-test-id='RegisterWithInvite.Name'
          />
          {this.renderFieldError('name')}
        </div>

        <div
          className={classNames('form-row password-row', {
            'with-error': Boolean(this.renderFieldError('password')),
          })}
        >
          <label htmlFor='password'>{t(`Register: field password label`)}</label>
          <InputText
            type={showPassword ? 'text' : 'password'}
            name='password'
            id='password'
            innerRef={this.form.password}
            data-test-id='RegisterWithInvite.Password'
          />
          <Icon name={showPassword ? 'faEyeSlash' : 'faEye'} size='lg' onClick={this.togglePasswordShow} />
          {this.renderFieldError('password')}
        </div>
        <div className='form-row'>
          <label>
            <Checkbox
              name='checkbox'
              onChange={this.toggleTermsOfUseLink}
              invalid={Boolean(termsOfUseError)}
              value={isTermsOfUseChecked}
              data-test-id='RegisterWithInvite.Checkbox'
              label={
                <span>
                  {t('Register: I agree with')}
                  <a href={termsOfUseUrl} target='_blank' rel='noopener noreferrer'>
                    {t('Register: ToS link text')}
                  </a>{' '}
                  {t('and')}{' '}
                  <a href={privacyPolicyUrl} target='_blank' rel='noopener noreferrer'>
                    {t('Register: policy link text')}
                  </a>
                </span>
              }
            />
          </label>
        </div>
      </>
    );
  };

  renderButtons = () => {
    const { domainOptions, appConfig } = this.context;
    const { location } = this.props;
    const parsedLocationSearch = queryString.parse(location.search);

    const notPartOfCC = !domainOptions?.partOfConversationalCloud;
    const invitationEmail = parsedLocationSearch.invitationEmail as string;
    const invitationAccountId = parsedLocationSearch.invitationAccountId as string;
    return (
      <div className='base-page_formarea-buttons'>
        <Button color='primary' data-test-id='RegisterWithInvite.Submit'>
          {t('Register: submit button text')}
        </Button>
        <div className='to-other-login'>
          {appConfig.authorization.oauth2AuthorizationEnabled && (
            <>
              <p>{t('Register: register with')}</p>
              <div className={classNames('to-other-login-buttons', { 'not-part-of-cc': notPartOfCC })}>
                <Button
                  color='secondary'
                  outline
                  onClick={() => this.loginWithProvider('google', invitationEmail, invitationAccountId)}
                  data-test-id='RegisterWithInvite.Oauth.Google'
                >
                  {google} {t('Login: login with google')}
                </Button>
                {!notPartOfCC && (
                  <Button
                    color='secondary'
                    outline
                    onClick={() => this.loginWithProvider('github', invitationEmail, invitationAccountId)}
                    data-test-id='RegisterWithInvite.Oauth.Github'
                  >
                    {github} {t('Login: login with github')}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  renderHead = () => {
    return (
      <div className='base-page_formarea-head register-form-head'>
        <h1>{t('Register: form header text')}</h1>
      </div>
    );
  };

  renderFooter = () => {
    const { appConfig } = this.context;

    if (!appConfig.authorization.externalSsoEnabled) return null;

    return (
      <div className='base-page_formarea-footer register'>
        <p>
          <NavLink to='/c/external-sso' data-test-id='RegisterWithInvite.Sso'>
            {t('Login: externalSso')}
          </NavLink>
        </p>
      </div>
    );
  };
}
